<template lang="pug">
  jumbotron
    template(v-slot:content)
      appDatepicker(
        :value="$route.query[JUMBOTRON_FILTERS.CREATED_AT_AFTER]"
        :class="{'active': dateFrom}"
        label="Date from"
        @input="setFilter(JUMBOTRON_FILTERS.CREATED_AT_AFTER, $event)"
        @change="setFilter(JUMBOTRON_FILTERS.CREATED_AT_AFTER, $event)"
        @clear="resetFilter(JUMBOTRON_FILTERS.CREATED_AT_AFTER, $event)"
        no-title
        calendar
        :max="getToday"
      )

      appDatepicker(
        :value="$route.query[JUMBOTRON_FILTERS.CREATED_AT_BEFORE]"
        :class="{'active': dateTo}"
        label="Date to"
        @input="setFilter(JUMBOTRON_FILTERS.CREATED_AT_BEFORE, $event)"
        @change="setFilter(JUMBOTRON_FILTERS.CREATED_AT_BEFORE, $event)"
        @clear="resetFilter(JUMBOTRON_FILTERS.CREATED_AT_BEFORE, $event)"
        no-title
        calendar
        :max="getToday"
      )

      app-select(
        v-if="isApplicationsPage || isTransactionsPage"
        :options="drivingSchools"
        :value="+$route.query[JUMBOTRON_FILTERS.DRIVING_SCHOOL]"
        @input="setFilter(JUMBOTRON_FILTERS.DRIVING_SCHOOL, $event)"
        placeholder="Driving school"
        slug-key='id'
        slug-value='name'
        searchable
        show-close-btn
        filter
      )

      ghost-filter(
        v-if="isRouteQueryHasTransactionID"
        label="Transaction ID:"
        :value="+$route.query[JUMBOTRON_FILTERS.TRANSACTION]"
        :active="isRouteQueryHasTransactionID"
        @clear="resetFilter(JUMBOTRON_FILTERS.TRANSACTION, $event)"
      )

      ghost-filter(
        v-if="isRouteQueryHasID"
        label="Transaction ID:"
        :value="+$route.query[JUMBOTRON_FILTERS.ID]"
        :active="isRouteQueryHasID"
        @clear="resetFilter(JUMBOTRON_FILTERS.ID, $event)"
      )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { JUMBOTRON_FILTERS } from '../core/drivingSchool-const'
import appDatepicker from '@/components/global/fields/appDatepicker.vue'
import ROUTE_NAMES from '@/app/admin/router/router-const'

export default {
  name: 'DrivingSchoolFilters',

  props: {
    loading: { type: Boolean, default: false }
  },

  data: () => ({
    JUMBOTRON_FILTERS
  }),

  computed: {
    ...mapGetters({
      drivingSchools: 'crmDrivingSchools/items',
    }),

    dateFrom() {
      if (this.$route.query[JUMBOTRON_FILTERS.CREATED_AT_AFTER]) return this.$route.query[JUMBOTRON_FILTERS.CREATED_AT_AFTER]
      else return ''
    },

    dateTo() {
      if (this.$route.query[JUMBOTRON_FILTERS.CREATED_AT_BEFORE]) return this.$route.query[JUMBOTRON_FILTERS.CREATED_AT_BEFORE]
      else return ''
    },

    isRouteQueryHasTransactionID() {
      return !!this.$route.query[JUMBOTRON_FILTERS.TRANSACTION]
    },

    isRouteQueryHasID() {
      return !!this.$route.query[JUMBOTRON_FILTERS.ID]
    },

    getToday() {
      return this.$dates().format('YYYY-MM-DD')
    },

    isListPage() {
      return this.$route.name === ROUTE_NAMES.DRIVING_SCHOOLS
    },

    isApplicationsPage() {
      return this.$route.name === ROUTE_NAMES.DRIVING_SCHOOLS_APPLICATIONS
    },

    isTransactionsPage() {
      return this.$route.name === ROUTE_NAMES.DRIVING_SCHOOLS_TRANSACTIONS
    },
  },

  async mounted() {
    await this.getDrivingSchoolItems({})
  },

  methods: {
    ...mapActions({
      getDrivingSchoolItems: 'crmDrivingSchools/items'
    }),

    async setFilter(type, payload, load = true) {
      await this.$emit('set:filter', type, payload, load)
    },

    resetFilter(type) {
      if (this.loading) return
      if (!this.$route.query[type]) return
      this.setFilter([type], undefined)
    },

    async setQuery(data, load) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ...data}})
      if (load) await this.$emit('needToUpdate')
    }
  },

  components: {
    appDatepicker,
    appSelect: () => import('@/components/global/actions/BaseSelect.vue'),
    jumbotron: () => import('@/components/global/fields/Jumbotron.vue'),
    ghostFilter: () => import('@/components/global/fields/GhostFilter.vue'),
  }
}
</script>

<style lang="scss">
.filter-select {
  .app-input__wrapper {
    background-color: #f5f7f9;
    border: none;
    box-shadow: none;

    .app-input__input {
      &::placeholder {
        color: $placeholder-color;
      }
    }
  }
}
</style>